import React from "react";

const goBack = {
  padding: "1rem",
  background: "linear-gradient(90deg,#377fea 20%,#653dea) #377fea",
};

const Navbar = ({}) => (
  <div className="is-fullheight" style={{ ...goBack }}>
    <div className="container">
      <a className="button is-warning" href="https://horsecs.com.br">
        Voltar ao Site
      </a>
    </div>
  </div>
);

export default Navbar;
